<template>
  <div class="container-fluid">
    <br />
    <h3>Els meus centres</h3>
    <br /><br />

    <table class="table">
      <thead>
        <tr>
          <th scope="col">Codi</th>
          <th scope="col">Nom</th>
          <th scope="col">Accions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(centre, index) in centres" :key="index">
          <td>{{ centre.codiCentre }}</td>
          <td>{{ centre.nom }}</td>
          <td style="width: 300px">
            <router-link
              :to="`/informes/${centre.id}`"
              class="nav-link floatLeft noMargin"
            >
              <button type="button" class="btn btn-secondary btn-sm">
                <font-awesome-icon icon="pen" />
                Marcar dies
              </button>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import InformeService from "@/services/informes.service.js";

export default {
  name: "User",

  data() {
    return {
      centres: [],
      baseUrl: "",
      accessToken: null,
      content: "",
      showNouInforme: false,
      centre: { id: 0, nom: "Carregant", preuMenjador: 0, preuBeca: 0 },
      llistats: {
        mesos: [
          { id: 1, nom: "Gener" },
          { id: 2, nom: "Febrer" },
          { id: 3, nom: "Març" },
          { id: 4, nom: "Abril" },
          { id: 5, nom: "Maig" },
          { id: 6, nom: "Juny" },
          { id: 7, nom: "Juliol" },
          { id: 8, nom: "Agost" },
          { id: 9, nom: "Setembre" },
          { id: 10, nom: "Octubre" },
          { id: 11, nom: "Novembre" },
          { id: 12, nom: "Desembre" },
        ],
        anys: [],
        tipusInforme: [
          { id: 1, nom: "Certificat menjador AIM" },
          { id: 2, nom: "Certificat menjador preceptius" },
        ],
      },
    };
  },
  methods: {},
  async mounted() {
    this.baseUrl = process.env.VUE_APP_API_URL;
    let user = JSON.parse(localStorage.getItem("user"));
    if (user.access_token) {
      this.accessToken = user.access_token;
    }
    InformeService.getMeusCentres().then(
      (response) => {
        const res = response.data;
        if (res.success) {
          this.centres = res.data.centres;
        }
      },
      () => {}
    );
  },
};
</script>
<style scoped>
.td {
  margin: 0px !important;
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
  }
  80%,
  90% {
    stroke: #ffa700;
  }
}

.estat-0,
.estat--1 {
  color: #d62d20;
}

.estat-1 {
  color: #d66320;
}

.estat-2,
.estat-3,
.estat-4,
.estat-5 {
  color: cadetblue;
}

.floatLeft {
  float: left !important;
}

.noMargin {
  margin: 0px 2px 0px 0px;
  padding: 0px;
}

.textRetorn {
  font-size: 9px;
  font-style: italic;
  line-height: 9px;
  padding: 0px;
  margin: 0px;
}
</style>
